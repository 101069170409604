<template>
  <div>
    <div class="modal fade" id="modal_informe_excel">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Informe Almacén Bonos Excel</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-InfoTurno"
                  data-toggle="tab"
                  href="#check_filtros"
                  >listado</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-InfoTurno"
                  data-toggle="tab"
                  href="#input_filtros"
                  >filtros</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="check_filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_codigo"
                              v-model="check.codigo"
                            />
                            <label
                              class="custom-control-label"
                              for="check_codigo"
                              :class="check.codigo ? 'text-primary' : ''"
                              >Código</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_guia"
                              v-model="check.guia"
                            />
                            <label
                              class="custom-control-label"
                              for="check_guia"
                              :class="check.guia ? 'text-primary' : ''"
                              >Guía</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_viaje"
                              v-model="check.viaje"
                            />
                            <label
                              class="custom-control-label"
                              :class="check.viaje ? 'text-primary' : ''"
                              for="check_viaje"
                              >Viaje</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_conductor"
                              v-model="check.conductor"
                            />
                            <label
                              class="custom-control-label"
                              for="check_conductor"
                              :class="check.conductor ? 'text-primary' : ''"
                              >Conductor</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_vehiculo"
                              v-model="check.vehiculo"
                            />
                            <label
                              class="custom-control-label"
                              for="check_vehiculo"
                              :class="check.vehiculo ? 'text-primary' : ''"
                              >Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_transportadora"
                              v-model="check.empresa"
                            />
                            <label
                              class="custom-control-label"
                              for="check_transportadora"
                              :class="check.empresa ? 'text-primary' : ''"
                              >Transportadora</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_sitio_origen"
                              v-model="check.sitio_origen"
                            />
                            <label
                              class="custom-control-label"
                              for="check_sitio_origen"
                              :class="check.sitio_origen ? 'text-primary' : ''"
                              >Sitio Origen</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_sitio_destino"
                              v-model="check.sitio_destino"
                            />
                            <label
                              class="custom-control-label"
                              for="check_sitio_destino"
                              :class="check.sitio_destino ? 'text-primary' : ''"
                              >Sitio Destino</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_tipo_bono"
                              v-model="check.tipo_bono"
                            />
                            <label
                              class="custom-control-label"
                              for="check_tipo_bono"
                              :class="check.tipo_bono ? 'text-primary' : ''"
                              >Tipo Bono</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_fecha"
                              v-model="check.fecha"
                            />
                            <label
                              class="custom-control-label"
                              for="check_fecha"
                              :class="check.fecha ? 'text-primary' : ''"
                              >Fecha</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_acta"
                              v-model="check.acta"
                            />
                            <label
                              class="custom-control-label"
                              for="check_acta"
                              :class="check.acta ? 'text-primary' : ''"
                              >Link Acta</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="check_estado"
                              v-model="check.estado"
                            />
                            <label
                              class="custom-control-label"
                              for="check_estado"
                              :class="check.estado ? 'text-primary' : ''"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="input_filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-12">
                      <label :class="form.guia ? 'text-success' : ''"
                        >Guía</label
                      >
                      <input
                        type="number"
                        class="form-control form-control-sm shadow-sm"
                        :class="form.guia ? 'border-success' : ''"
                        v-model="form.guia"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label :class="form.viaje_id ? 'text-success' : ''"
                        >Viaje</label
                      >
                      <input
                        type="number"
                        class="form-control form-control-sm shadow-sm"
                        :class="form.viaje_id ? 'border-success' : ''"
                        v-model="form.viaje_id"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label :class="form.vehiculo ? 'text-success ' : ''"
                        >Vehículo</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-sm shadow-sm"
                        :class="form.vehiculo ? 'border-success ' : ''"
                        v-model="form.vehiculo"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label :class="form.conductor_id ? 'text-success ' : ''"
                        >Conductor</label
                      >
                      <v-select
                        v-model="conductor"
                        placeholder="Conductor"
                        label="nombre"
                        class="form-control form-control-sm shadow-sm p-0"
                        :class="form.conductor_id ? 'border-success ' : ''"
                        :options="listasForms.conductores"
                        :filterable="false"
                        @search="buscarConductores"
                        @input="selectConductor()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-12">
                      <label :class="form.empresa_id ? 'text-success ' : ''"
                        >Transportadora</label
                      >
                      <v-select
                        v-model="empresa"
                        placeholder="Empresa"
                        label="razon_social"
                        class="form-control form-control-sm shadow-sm p-0 col-md-12"
                        :class="form.empresa_id ? 'border-success ' : ''"
                        :options="listasForms.empresas"
                        @search="buscarTransportadora"
                        @input="selectTransportadora()"
                      ></v-select>
                    </div>

                    <div class="form-group col-md-12">
                      <label :class="form.sitio_origen_id ? 'text-success' : ''"
                        >Sitio Origen</label
                      >
                      <div class="mt-0">
                        <v-select
                          v-model="sitioOrigen"
                          placeholder="Sitio"
                          label="nombre"
                          class="form-control form-control-sm shadow-sm p-0 col-md-12"
                          :class="form.sitio_origen_id ? 'border-success' : ''"
                          :options="listasForms.sitios"
                          @search="buscarSitios"
                          @input="selectSitioOrigen()"
                        ></v-select>
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <label
                        :class="form.sitio_destino_id ? 'text-success ' : ''"
                        >Sitio Destino</label
                      >
                      <div class="mt-0">
                        <v-select
                          v-model="sitioDestino"
                          placeholder="Sitio"
                          label="nombre"
                          class="form-control form-control-sm shadow-sm p-0 col-md-12"
                          :class="
                            form.sitio_destino_id ? 'border-success ' : ''
                          "
                          :options="listasForms.sitios"
                          @search="buscarSitios"
                          @input="selectSitioDestino()"
                        ></v-select>
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label :class="form.tipo_bono ? 'text-success' : ''"
                        >Tipo Bono</label
                      >
                      <select
                        class="form-control form-control-sm shadow-sm"
                        v-model="form.tipo_bono"
                        :class="form.tipo_bono ? 'border-success' : ''"
                      >
                        <option value="">seleccionar...</option>
                        <option
                          v-for="tipoBono in $parent.listasForms.tipo_bonos"
                          :key="tipoBono.numeracion"
                          :value="tipoBono.numeracion"
                        >
                          {{ tipoBono.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label :class="form.estado ? 'text-success' : ''"
                        >Estado</label
                      >
                      <select
                        class="form-control form-control-sm shadow-sm"
                        v-model="form.estado"
                        :class="form.estado ? 'border-success' : ''"
                      >
                        <option value="">seleccionar...</option>
                        <option
                          v-for="estado in $parent.listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-6">
                      <label :class="form.fecha_ini ? 'text-success' : ''"
                        >Fecha Inicio</label
                      >
                      <input
                        type="date"
                        class="form-control form-control-sm shadow-sm"
                        v-model="form.fecha_ini"
                        :class="form.fecha_ini ? 'border-success' : ''"
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label :class="form.fecha_fin ? 'text-success' : ''"
                        >Fecha fin</label
                      >
                      <input
                        type="date"
                        class="form-control form-control-sm shadow-sm"
                        :class="form.fecha_fin ? 'border-success' : ''"
                        v-model="form.fecha_fin"
                        @input="validaFechas"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-gradient-navy shadow mr-2"
                @click="exportExcel"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
              <button
                type="button"
                class="btn btn-sm bg-gradient-danger shadow"
                @click="limpiarModal()"
              >
                Limpiar
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div></div
></template>
<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "BonoExportExcel",
  components: {
    vSelect,
  },
  data() {
    return {
      check: {
        codigo: true,
        guia: true,
        viaje: true,
        conductor: true,
        vehiculo: true,
        empresa: true,
        sitio_origen: true,
        sitio_destino: true,
        tipo_bono: true,
        fecha: true,
        estado: true,
        acta: true,
      },
      form: {
        guia: null,
        viaje_id: null,
        conductor_id: null,
        vehiculo: null,
        empresa_id: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        tipo_bono: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
      },
      listasForms: {
        sitios: [],
        conductores: [],
        empresas: [],
      },
      conductor: {},
      sitioDestino: {},
      sitioOrigen: {},
      empresa: {},
    };
  },

  methods: {
    limpiarModal() {
      this.check = {
        codigo: true,
        guia: true,
        viaje: true,
        conductor: true,
        vehiculo: true,
        empresa: true,
        sitio_origen: true,
        sitio_destino: true,
        tipo_bono: true,
        fecha: true,
        estado: true,
        acta: true,
      };
      this.form = {
        guia: null,
        viaje_id: null,
        conductor_id: null,
        vehiculo: null,
        empresa_id: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        tipo_bono: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
      };
      this.conductor = {};
      this.sitioDestino = {};
      this.sitioOrigen = {};
      this.empresa = {};
    },

    selectSitioDestino() {
      this.form.sitio_destino_id = null;
      if (this.sitioDestino) {
        this.form.sitio_destino_id = this.sitioDestino.id;
      }
    },

    selectSitioOrigen() {
      this.form.sitio_origen_id = null;
      if (this.sitioOrigen) {
        this.form.sitio_origen_id = this.sitioOrigen.id;
      }
    },

    selectConductor() {
      this.form.conductor_id = null;
      if (this.conductor) {
        this.form.conductor_id = this.conductor.id;
      }
    },

    selectTransportadora() {
      this.form.empresa_id = null;
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    buscarSitios(search) {
      if (search != "") {
        let me = this;
        me.$parent.cargando = true;
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            console.log(response);
            me.listasForms.sitios = response.data;
            me.$parent.cargando = false;
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.$parent.cargando = false;
          });
      }
    },

    buscarConductores(search) {
      if (search != "") {
        let me = this;
        me.$parent.cargando = true;
        var url = "api/admin/conductores/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.conductores = response.data;
            me.$parent.cargando = false;
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.$parent.cargando = false;
          });
      }
    },

    buscarTransportadora(search) {
      if (search != "") {
        let me = this;
        me.$parent.cargando = true;
        var url = "api/admin/empresas/lista?estado=1?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.empresas = response.data;
            me.$parent.cargando = false;
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.$parent.cargando = false;
          });
      }
    },

    exportExcel() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/almacenBonos/export",
        data: { form: this.form, check: this.check },
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    validaFechas() {
      if (this.form.fecha_ini) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.form.fecha_ini);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_ini && this.form.fecha_fin) {
        var fecha_menor = new Date(this.form.fecha_ini);
        var fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_ini && this.form.fecha_fin) {
        fecha_menor = new Date(this.form.fecha_ini).getTime();
        fecha_mayor = new Date(this.form.fecha_fin).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
  },
};
</script>
